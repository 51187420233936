<template>
  <component
    :is="componentName"
    :section-data="sectionData"
    :products-data="productsData"
    class="collection-push"
    :class="{ 'desktop-side': componentName === 'DesktopSide' }"
  />
</template>

<script>
import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'CollectionPush',
  components: {
    DesktopFullWidth: () => import('./declinations/DesktopFullWidth.vue'),
    DesktopSide: () => import('./declinations/DesktopSide.vue')
  },
  props: {
    sectionData: {
      required: true,
      type: Object
    }
  },

  setup(props) {
    const componentName = computed(() => {
      const componentsMap = {
        Top: 'DesktopFullWidth',
        Left: 'DesktopSide'
      };
      const position = props.sectionData?.visual?.posD;

      return componentsMap[position];
    });

    const productsData = computed(() => {
      const layoutsMap = {
        Carousel: 'slider',
        Grid: 'grid'
      };
      const item = props.sectionData?.itemsCollection?.items[0];

      return {
        listOfSkus: item?.sku || [],
        layout: layoutsMap[item.layout] || 'slider'
      };
    });

    return {
      componentName,
      productsData
    };
  }
});
</script>

<style lang="scss" scoped>
.collection-push {
  display: flex;
  align-items: center;
  flex-direction: column;
  &.desktop-side {
    display: grid;
    align-items: self-start;
  }
}
</style>
