import { render, staticRenderFns } from "./VaimoProductCard.vue?vue&type=template&id=3997b0dd&scoped=true&"
import script from "./VaimoProductCard.vue?vue&type=script&lang=ts&"
export * from "./VaimoProductCard.vue?vue&type=script&lang=ts&"
import style0 from "./VaimoProductCard.vue?vue&type=style&index=0&id=3997b0dd&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3997b0dd",
  null
  
)

export default component.exports