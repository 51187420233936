<template>
  <div class="collection-push-desktop-side grid-container">
    <div class="col-span-4 s:col-span-6 banner">
      <LoadWhenVisible>
        <VaimoBanner
          v-bind="visualData"
          :ratio="isDesktop ? '5:6' : visualData.ratio"
          class="collection-push-desktop-side__banner"
        />
        <VaimoButtons
          v-if="isDesktop"
          block
          :section-data="sectionData"
          class="collection-push-desktop-side__buttons text-center mt-sm"
        />
      </LoadWhenVisible>
    </div>
    <div class="col-end-13 col-span-7 s:col-span-6 col-content">
      <VaimoHeading
        v-if="headingData.heading"
        heading-level="h3"
        heading-style="emphasized"
        :heading="headingData.heading"
        :heading-link="headingData.headingLink"
        class="collection-push-desktop-side__heading s:text-center mb-sm"
      />
      <VaimoTruncatedText
        v-if="headingData.description"
        :text="headingData.description"
        truncate="by characters"
        :characters="145"
        class="collection-push-desktop-side__description s:text-center m:mb-sm"
      />
      <VaimoButtons
        v-if="!isDesktop && isButtonsExist"
        block
        :section-data="sectionData"
        class="collection-push-desktop-side__buttons text-center mt-sm"
      />
      <LoadWhenVisible class="col-content">
        <VaimoProducts
          :display="productsData.layout"
          :wrapper-props="getWrapperProps"
          :sku-list="productsData.listOfSkus"
          loading-type="spinner"
          class="collection-push-desktop-side__slider"
          unique-key="collection-push-desktop-side-slider"
        />
      </LoadWhenVisible>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, useContext } from '@nuxtjs/composition-api';

import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import { useFlexibleSection } from '~/diptyqueTheme/composable/useFlexibleSection';

export default defineComponent({
  name: 'DesktopSide',
  components: {
    LoadWhenVisible,
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoTruncatedText: () => import('atoms/VaimoTruncatedText.vue'),
    VaimoButtons: () => import('molecules/VaimoButtons.vue'),
    VaimoProducts: () => import('organisms/VaimoProducts.vue')
  },
  props: {
    sectionData: {
      required: true,
      type: Object
    },
    productsData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const { visualData, headingData } = useFlexibleSection(props.sectionData);
    const { app } = useContext();
    const { isDesktop } = app.$device;

    const isButtonsExist = computed(
      () => props.sectionData?.buttonCollection?.items?.length
    );

    const getWrapperProps = computed(() => {
      const breakpoints = [
        {
          maxWidth: 1920,
          maxItemsVisible: 4
        },
        {
          maxWidth: 1700,
          maxItemsVisible: 4
        },
        {
          maxWidth: 1140,
          maxItemsVisible: 4
        }
      ];

      return {
        slider: {
          desktopShift: 25,
          desktopQuantityVisible: 4,
          variableWidth: true,
          desktopCenterMode: false,
          responsive: breakpoints.map(({ maxWidth, maxItemsVisible }) => ({
            breakpoint: maxWidth,
            settings: {
              slidesToShow: maxItemsVisible,
              slidesToScroll: 2
            }
          }))
        }
      };
    });

    return {
      visualData,
      isButtonsExist,
      isDesktop,
      getWrapperProps,
      headingData
    };
  }
});
</script>

<style lang="scss" scoped>
.collection-push-desktop-side {
  .banner {
    @include for-screen-s {
      margin: 0 -20px;
    }
  }
  &__heading {
    ::v-deep {
      .vaimo-heading__title {
        @include for-screen-s {
          padding: 0 var(--spacer-md);
        }
      }
    }
  }

  &__description {
    font-size: var(--font-size--lg);
    max-width: 820px;
    margin: 0 auto;

    @include for-screen-s {
      max-width: 335px;
    }
  }
  &__slider {
    ::v-deep {
      .vaimo-slider__slider {
        padding: 0;
      }
      .vaimo-slider {
        margin-top: 15px;
        .slick-slider {
          .slick-slide {
            width: 190px;
            min-width: 190px;
            margin: 0 20px 0 0;

            @include for-screen-s {
              width: 150px;
              min-width: 150px;
            }
            .sf-product-card {
              padding: 0;
            }
          }
        }
      }
      .vaimo-grid {
        @include for-screen-s {
          padding: 0;
          gap: var(--spacer-3md) var(--spacer-md);
          .sf-product-card {
            padding: 0;
          }
        }
      }
    }
    @include for-screen-m-and-l {
      .slick-list {
        margin-left: -10px;
      }
    }
  }
  .col-content {
    min-width: 0;
    width: 100%;
  }
}
</style>
