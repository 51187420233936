

























































import {defineComponent, computed, useContext} from '@nuxtjs/composition-api';
import { useSection } from '@/diptyqueTheme/composable/useSection';
import { SfLink } from '@storefront-ui/vue';
import { useLink } from '~/diptyqueTheme/composable/useLink';
import spritesheet from '~/diptyqueTheme/assets/icons/sprite/spritesheet.json';

export default defineComponent({
  name: 'FooterMenuListDisplay',
  components: {
    BlockWrapper: () => import('templates/BlockWrapper.vue'),
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    SfLink,
  },
  props: {
    sectionData: {
      required: false,
      type: Object,
      default: () => ({})
    },
    isLastItem: {
      required: false,
      type: Boolean,
      default: false
    },
    blockName: {
      required: false,
      type: String,
      default: ''
    }
  },
  setup(props) {
    const {
      app: { $gtm }
    } = useContext();
    const { normalizeLink, getTargetVal } = useLink();
    const { headingData, getBlockElementClasses } = useSection(
      props.sectionData
    );
    const items = computed<Array<object>>(() => {
      return props.sectionData?.itemsCollection?.items
        .filter((i) => i)
        .map((i) => {
          if (spritesheet[i.label]) {
            i.iconSize = i.label === 'accessibility' ? 32 : 16;
            i.icon = i.label;
            i.label = '';
          }
          return i;
        });
    });
    const specialLastItem = computed<boolean>(
      () => props.sectionData?.specialLastItem
    );
    const isSpecial = (index: number): boolean => {
      return specialLastItem.value && items.value.length === index + 1;
    };
    const getDisplayClass = computed<string>(() => {
      const displayTypeMap = {
        'default list': 'default',
        'emphasised list': 'emphasised'
      };

      return displayTypeMap[props.sectionData?.display];
    });
    const getClickFooterNavDetails = (link) => {
      if (props.sectionData?.menuTitle === 'Footer - Follow us menu block') {
        $gtm.push({
          event: 'clickFooterSocial',
          socialName: link.title
        })
      }
      $gtm.push({
        event: 'clickFooterNav',
        category: props.blockName,
        link: link.title
      })
    }
    return {
      headingData,
      items,
      specialLastItem,
      isSpecial,
      getDisplayClass,
      elemClasses: getBlockElementClasses,
      normalizeLink,
      getTargetVal,
      getClickFooterNavDetails
    };
  }
});
