















































import {
  computed,
  defineComponent,
  PropType,
  ref,
  ssrRef,
  useContext,
  useFetch,
  watch
} from '@nuxtjs/composition-api';
import { SfLoader } from '@storefront-ui/vue';
import VaimoProductCard from 'organisms/VaimoProductCard.vue';

import { useImage, useProduct } from '~/composables';
import VaimoGrid from '~/diptyqueTheme/components/molecules/VaimoGrid.vue';
import VaimoSlider from '~/diptyqueTheme/components/molecules/VaimoSlider.vue';
import {
  useAlternativeVisual,
  useGoogleTagManager
} from '~/diptyqueTheme/composable';
import groupTypes from '~/diptyqueTheme/config/productGroupTypes';
import { validateFraction } from '~/diptyqueTheme/helpers/priceFormatter';
import customProductGetters from '~/diptyqueTheme/helpers/productGetters';
import { useAddToCart } from '~/helpers/cart/addToCart';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import { Product } from '~/modules/catalog/product/types';
import { Visual } from '~/diptyqueTheme/types/contentful';
import { useNosto } from '~/diptyqueTheme/composable/useNosto';


export default defineComponent({
  name: 'VaimoProducts',
  components: {
    VaimoProductCard,
    VaimoSlider,
    VaimoGrid,
    SfLoader
  },
  props: {
    uniqueKey: {
      required: false,
      type: String,
      default: 'VaimoProducts'
    },
    skuList: {
      required: false,
      type: Array as PropType<string[]>,
      default: () => []
    },
    skuListUnsorted: {
      required: false,
      type: Array,
      default: () => []
    },
    skuListUnsortedFull: {
      required: false,
      type: Array,
      default: () => []
    },
    skuListType: {
      required: false,
      type: String,
      default: 'listOfSku'
    },
    nostoId: {
      required: false,
      type: String,
      default: ''
    },
    display: {
      required: false,
      type: String,
      default: 'slider',
      validator: (val: string) => ['slider', 'grid'].includes(val)
    },
    wrapperProps: {
      required: false,
      type: Object,
      default: () => ({})
    },
    pageSize: {
      type: Number,
      required: false,
      default: 20
    },
    loadingType: {
      required: false,
      type: String,
      validator: (val: string) => ['spinner'].includes(val)
    },
    gridColsMobile: {
      type: Number,
      required: false,
      default: 2
    },
    gridColsDesktop: {
      type: Number,
      required: false,
      default: 4
    },
    isCollection: {
      type: Boolean,
      required: false,
      default: false
    },
    altVisuals: {
      type: Array as PropType<Visual[]>,
      required: false,
      default: () => ([] as Visual[])
    },
    getProductsByCategory: Boolean,
    categoryData: {
      required: false,
      type: Object,
      default: () => ({})
    }
  },
  emits: ['productsLoaded'],
  setup(props, { emit }) {
    const { app } = useContext();
    const { getViewItemListDetails, getSelectItemDetails } =
      useGoogleTagManager();
    const { getMagentoImage } = useImage();
    const { getProductList, loading: productsLoading } = useProduct();
    const { updateNostoSessionWithoutRecommendations, recommendationTypes, recommendedProductsBySlot } = useNosto();
    const { applyAltVisuals } = useAlternativeVisual();
    const {
      addItemToCart,
      isInCart,
      loading: addToCartLoading
    } = useAddToCart();

    const SKUsUnsorted = computed(() => props.skuListUnsorted);
    const SKUsUnsortedFull = computed(() => props.skuListUnsortedFull);
    const SKUs = computed(() => props.skuList);
    const SKUsType = computed(() => props.skuListType);
    const nostoSlotId = computed(() => props.nostoId);
    const nostoProducts = computed(() => {
      return props.skuListType === groupTypes.NOSTO
        ? recommendedProductsBySlot.value
        : {}
    });

    const getProducts = async () => {
      const loadedSKUs = products.value.map((p) => p.sku).sort();

      if (
        props.getProductsByCategory &&
        Object.keys(props.categoryData)?.length
      ) {
        await getProductsByCategoryID();
      }

      if (Array.isArray(SKUs.value)) {
        if (loadedSKUs[0] === SKUs.value[0]) return;
      }

      if (SKUsType.value == groupTypes.NOSTO && nostoProducts.value[props.nostoId]) {
        await getProductsBySku(nostoProducts.value[props.nostoId], SKUsType.value);

      } else if (SKUs.value?.length && !props.getProductsByCategory) {
        //@ts-ignore
        await getProductsBySku(SKUs.value, SKUsType.value);
      }

      if (products.value) {
        app.$gtm.push(getViewItemListDetails(products.value, 'slider'));
      }
    };

    const products = ssrRef([], 'VaimoProducts' + props.uniqueKey);

    const getProductsByCategoryID = async () => {
      try {
        const data = await getProductList({
          filter: {
            category_id: {
              eq: props.categoryData.categoryID
            }
          },
          pageSize: props.categoryData.limit
        });
        products.value = data?.items;
      } catch (e) {
        console.error('getProductsByCategoryID', e);
      }
    };

    const getProductsBySku = async (sku_array: string[], type: string) => {
      const filterType = type === groupTypes.NOSTO ? '_id' : 'sku';
      try {
        emit('productsLoaded', false);
        const data = await getProductList({
          filter: {
            [filterType]: {
              in: sku_array
            }
          },
          pageSize: props.pageSize
        });

        const result = data?.items;

        if (props.isCollection) {
          if (SKUsUnsortedFull.value.length) {
            result.sort(function (a, b) {
              return (
                SKUsUnsortedFull.value.indexOf(a.sku) -
                SKUsUnsortedFull.value.indexOf(b.sku)
              );
            });
          } else {
            result.sort(function (a, b) {
              return (
                SKUsUnsorted.value.indexOf(a.sku) -
                SKUsUnsorted.value.indexOf(b.sku)
              );
            });
          }
        } else {
          const sortElem = type === groupTypes.NOSTO ? 'id' : 'sku';
          result.sort((a, b) => {
            return (
              sku_array.indexOf(String(a[sortElem])) -
              sku_array.indexOf(String(b[sortElem]))
            );
          });
        }
        products.value = applyAltVisuals(result as Product[], props.altVisuals);
      } catch (e) {
        console.error(e);
      } finally {
        emit('productsLoaded', true);
      }
    };

    watch(SKUs, async () => {
      await getProducts();
    });

    useFetch(async () => {
      await getProducts();
    });

    const onGtmSelectItem = (product: Product, index: number, event): void => {
      // Prevents GTM event from firing when clicking on the add to cart button
      if (event.target.closest('.vaimo-product-card__add-to-cart')) {
        return;
      }

      app.$gtm.push(getSelectItemDetails(product, index, 'slider'));
    };

    const addNostoRefParam = (link: string): string => {
      if (nostoSlotId.value) {
        let params = new URLSearchParams([['nosto_ref', nostoSlotId.value]]);
        return link + '?' + params.toString();
      }
      return link;
    };

    const getProductLink = (product: Product): string => {
      return `${productGetters.getSlug(product, product?.categories?.[0])}`;
    };

    const handleClickOnProduct = (product: Product, index: number, event) => {
      onGtmSelectItem(product, index + 1, event);
    }

    const addToCartLoadingSKU = ref();

    const handleAddToCart = async (product: Product) => {
      if (nostoSlotId.value) {
        const options = { ref: nostoSlotId.value }
        updateNostoSessionWithoutRecommendations(recommendationTypes.product, String(product.id), options);
      }
    }

    const getAddToCartLoadingFlag = (product) => {
      return (
        addToCartLoading.value && addToCartLoadingSKU.value === product.sku
      );
    };

    const wrapperComponent = computed(() => {
      if (props.display === 'slider') return 'VaimoSlider';
      if (props.display === 'grid') return 'VaimoGrid';
    });

    const wrapperDisplayProps = computed(() => {
      return props.wrapperProps[props.display] || {};
    });

    const itemsLength = computed(() => {
      return products.value?.length || 0;
    });

    const wrapperClasses = computed(() => {
      if (props.display === 'grid') {
        return [
          'grid-cols-' + props.gridColsMobile,
          'm-and-l:grid-cols-' + props.gridColsDesktop
        ];
      }
      return '';
    });

    const getProductVariantName = (product: Product): string | null => {
      if (!product || !product['variant_name']) {
        return null;
      }

      const variant_name = product['variant_name'];
      return variant_name[0]?.label ?? null;
    };

    return {
      productGetters,
      customProductGetters,
      products,
      productsLoading,
      getProductLink,
      getMagentoImage,
      isInCart,
      addToCartLoading,
      addToCartLoadingSKU,
      getAddToCartLoadingFlag,
      handleAddToCart,
      handleClickOnProduct,
      wrapperComponent,
      wrapperDisplayProps,
      wrapperClasses,
      itemsLength,
      validateFraction,
      addNostoRefParam
    };
  }
});
