













import { SfLoader } from '@storefront-ui/vue';
import {
  defineComponent,
  ref,
  useContext,
  useRoute,
  computed,
  useMeta,
  onMounted
} from '@nuxtjs/composition-api';
import { useCache, CacheTagPrefix } from '@vue-storefront/cache';
import { useContent } from '~/composables';
import type { CmsPage } from '~/modules/GraphQL/types';
import HTMLContentMagento from '~/components/HTMLContentMagento.vue';

export default defineComponent({
  name: 'PageMagento',
  components: {
    HTMLContentMagento,
    SfLoader
  },
  props: {
    identifier: {
      type: [String],
      default: ''
    }
  },
  setup(props) {
    const { addTags } = useCache();
    const { loadPage, loadBlocks, error, loading } = useContent();

    const route = useRoute();
    const { error: nuxtError, app } = useContext();
    const { params } = route.value;
    const page = ref<CmsPage | null>(null);

    // eslint-disable-next-line consistent-return
    const loadCmsPage = async () => {
      page.value = await loadPage({
        identifier: params.slug || props.identifier
      });

      if (error?.value?.page || !page.value) {
        nuxtError({ statusCode: 404 });
        return;
      }

      addTags([{ prefix: CacheTagPrefix.View, value: page.value.identifier }]);
    };

    loadCmsPage();

    const metaTitle = computed(() => {
      let title = page.value?.meta_title
        ? page.value?.meta_title
        : page.value?.title + ' | Diptyque Paris';

      if (title) {
        return title;
      }

      return null;
    });

    const metaTags = computed(() => {
      return metaTitle.value
        ? {
            title: metaTitle.value,
            meta: [
              {
                hid: 'og:title',
                property: 'og:title',
                content: metaTitle.value
              },
              {
                hid: 'description',
                name: 'description',
                content: page.value?.meta_description
              },
              {
                hid: 'og:description',
                name: 'og:description',
                content: page.value?.meta_description
              }
            ]
          }
        : {};
    });

    useMeta(() => metaTags.value);
    const isCmsSidebarVisible = ref<boolean>(false);

    onMounted(async () => {
      var parent = document.getElementsByClassName('magento-css');
      var link = document.createElement('link');
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = 'https://www.diptyqueparis.com/media/landing-pages/old_pages/css/magento.css';
      link.media = 'all';
      parent[0].appendChild(link);

      var link2 = document.createElement('link');
      link2.rel = 'stylesheet';
      link2.type = 'text/css';
      link2.href = 'https://www.diptyqueparis.com/media/landing-pages/old_pages/css/animations_old.css';
      link2.media = 'all';
      parent[0].appendChild(link2);

      var link6 = document.createElement('script');
      link6.type = 'text/javascript';
      link6.src = 'https://www.diptyqueparis.com/media/landing-pages/old_pages/js/oldscripts.js';
      parent[0].appendChild(link6);
    });

    return {
      page,
      loading,
      isCmsSidebarVisible
    };
  },
  head: {}
});
