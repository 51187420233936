




























































import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref
} from '@nuxtjs/composition-api';
import { SfLink } from '@storefront-ui/vue';

import { useDeclination, useLink } from '~/diptyqueTheme/composable';

interface SectionDataInterface {
  itemsCollection: {
    items: Array<{ [key: string]: unknown; __typename: string }>;
  };
  countdownEndDataTime: string | undefined | null;
  countdownShowHideSeconds: boolean | undefined | null;
  eventTitle: string | null;
  eventDescr: string | null;
}

export default defineComponent({
  name: 'CountdownPush',
  components: {
    VaimoTruncatedText: () => import('atoms/VaimoTruncatedText.vue'),
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoButtons: () => import('molecules/VaimoButtons.vue'),
    CountdownTimer: () => import('atoms/CountdownTimer.vue'),
    SfLink
  },
  props: {
    uniqueKey: {
      required: true,
      type: String
    },
    sectionData: {
      required: true,
      type: Object as PropType<SectionDataInterface>
    }
  },
  setup(props) {
    const MODULE_DEFAULT = 'Default for current module';
    const MODULE_DEFAULT_TWO = 'Choose a value';
    const { getVisualData } = useDeclination(props.sectionData);
    const { normalizeLink } = useLink();
    const isCountdownExpired = ref(false);
    const calendarData = ref<{
      title?: string;
      description?: string;
      endDate?: string;
      isExpired?: boolean;
    }>({});

    const isVisualVisible = computed(
      () => getVisualData.value && !getVisualData.value.visible
    );
    const visualRatio = computed(() =>
      getVisualData.value.ratio === MODULE_DEFAULT ||
      getVisualData.value.ratio === MODULE_DEFAULT_TWO
        ? '1:1'
        : getVisualData.value.ratio
    );
    const ratioClass = computed(
      () => `ratio-${visualRatio.value?.replace(':', '-') ?? '1:1'}`
    );
    const positionClass = computed(() =>
      getVisualData.value.position === 'Right'
        ? 'visual-pos-right'
        : 'visual-pos-left'
    );

    const countdownDate = computed(
      () => props.sectionData?.countdownEndDataTime ?? ''
    );

    const disableSeconds = computed(
      () => props.sectionData?.countdownShowHideSeconds ?? false
    );

    const generateCalendarData = (): void => {
      calendarData.value = {
        endDate: countdownDate.value,
        isExpired: isCountdownExpired.value
      };
    };

    const setCountdownExpiredFlag = (flag: boolean) => {
      isCountdownExpired.value = flag;
      generateCalendarData();
    };

    onMounted(() => {
      generateCalendarData();
    });

    return {
      getVisualData,
      visualRatio,
      ratioClass,
      positionClass,
      setCountdownExpiredFlag,
      calendarData,
      normalizeLink,
      isVisualVisible,
      countdownDate,
      disableSeconds
    };
  }
});
