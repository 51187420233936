


















































































































































































































import {
  KlevuFetch,
  KlevuLastSearches,
  KlevuTypeOfRecord,
  search,
  searchCategory,
  suggestions,
  trendingProducts
} from '@klevu/core';
import {
  defineComponent,
  onUpdated,
  ref,
  useContext,
  useRoute,
  watch
} from '@nuxtjs/composition-api';
import { focusTrap } from '@storefront-ui/vue/src/utilities/directives';
import { debounce } from 'lodash-es';

import { clickOutside } from '~/components/directives/click-outside/click-outside-directive';
import getQueryDefaultCategories from '~/diptyqueTheme/customQueries/magento/getQueryDefaultCategories';
import { useMegaMenuMobileStore } from '~/diptyqueTheme/stores/megaMenuMobile';

import { useKlevuSearch, useQuickSearch } from '../index';

export default defineComponent({
  name: 'VaimoHeaderSearchBar',
  components: {
    VaimoQuickSearchProducts: () =>
      import('./products/VaimoQuickSearchProducts.vue'),
    VaimoQuickFilterBadge: () =>
      import(
        '~/diptyqueTheme/components/organisms/category/VaimoQuickFilterBadge.vue'
      )
  },
  directives: { clickOutside },
  props: {
    isSearchOpen: {
      type: Boolean,
      default: false
    },
    searchOnInit: {
      type: Boolean,
      default: false
    },
    itemsPerPage: {
      type: Number,
      default: 12
    },
    minTermLen: {
      type: Number,
      default: 3
    },
    searchId: {
      type: String,
      default: ''
    }
  },
  emits: ['set-is-open', 'set-search-results'],
  setup(props, { root }) {
    const megaMenuMobileStore = useMegaMenuMobileStore();

    const inputQuickSearch = ref(null);
    const container = ref(null);
    const containerBind = ref(false);

    let skuListTrending: any = ref([]);
    let suggestionsList: any = ref([]);
    let pagesInitial: any = ref([]);
    let skuList: any = ref([]);
    let productsNumber: any = ref(0);
    let openedWrapper: any = ref('');
    let notFound = ref(false);

    useKlevuSearch().init();
    const route = useRoute();
    const quickSearchStore = useQuickSearch();
    const {
      app: {
        i18n,
        $gtm,
        $vsf: { $magento },
        $klevuSearch: { quickSearchLimit, searchPath, typeOfRecords }
      }
    } = useContext();

    const isSearchPage = () => route.value.path.includes(searchPath);
    // @ts-ignore
    let locale = i18n.localeProperties?.code;
    if (!locale) {
      locale = 'en_eu';
    }
    let baseUrl = process.env.KLEVU_SEARCH_BASE_URL;
    if (!baseUrl) {
      baseUrl = 'https://www.diptyqueparis.com/';
    }
    const searchResultsUrl = baseUrl + locale + searchPath;

    const clearSearchResults = () => {
      quickSearchStore.setProducts([]);
      quickSearchStore.setCmsPages([]);
      quickSearchStore.setSuggestions([]);
      quickSearchStore.setTrendingProducts([]);

      skuListTrending.value = [];
      suggestionsList.value = [];
      pagesInitial.value = [];
      skuList.value = [];
      productsNumber.value = 0;
    };

    const buildSearchURL = (type: string) => {
      const url = new URL(searchResultsUrl);
      url.searchParams.append(type, '1');
      url.searchParams.append('q', quickSearchStore.searchTerm);

      return url.toString();
    };

    const closeQuickSearchOut: any = (event) => {
      if (
        !quickSearchStore.quickSearchOpen &&
        openedWrapper.value !== 'opened'
      ) {
        return;
      }
      if (!event) {
        closeQuickSearch();
      }
      if (event.target.closest('.search-wrapper')) {
        return;
      }

      closeQuickSearch();
    };

    const closeQuickSearch: any = () => {
      if (!quickSearchStore.setQuickSearchOpen) {
        return;
      }

      const searchLayer = document.querySelector('.search-layer');
      if (searchLayer) searchLayer.classList.add('closing');
      const input = document.querySelector('.sf-input__wrapper');
      if (input) input.classList.add('shrink');
      const overlay = document.querySelector('.sf-overlay');
      if (overlay) overlay.classList.add('hidden');

      setTimeout(() => {
        toggleQuickSearch(false);
        openedWrapper.value = '';
        (document.activeElement as HTMLElement).blur();
        if (!isSearchPage()) {
          clearSearchResults();
        }
        if (input) input.classList.remove('shrink');
      }, 700);

      document && document.body.classList.remove('no-scroll');
    };

    const closeButtonHandler = () => {
      closeQuickSearch();

      $gtm.push({
        event: 'click_close_popin'
      });
    };

    const strippedHtml = (str): string => {
      let regex = /(<([^>]+)>)/gi;

      return str.replace(regex, '');
    };

    const getSlug = (url) => {
      return url.substring(url.indexOf(locale) + 5);
    };

    const isPageHasCorrectUrl = (url) => {
      return root.localePath(getSlug(url)).includes(locale);
    };

    const doEmptySuggestions = async () => {
      if (quickSearchStore.searchTerm.length < 3) {
        notFound.value = false;
        quickSearchStore.setLastSearches(KlevuLastSearches.get());

        if (quickSearchStore.trendingProducts.length) {
          return;
        }

        const res = await KlevuFetch(trendingProducts({ limit: 6 }));
        await populateCategoriesFromKlevuForEmptySuggestions();

        for (const productRow of res.queriesById('trendingProducts').records) {
          skuListTrending.value.push(productRow.sku);
        }

        quickSearchStore.setTrendingProducts(skuListTrending.value ?? []);
      } else {
        doSearch();
      }
    };

    const getDefaultCategories = async (): Promise<Object[]> => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore "customQueryCacheable" dynamically added by custom-middleware
      const { data } = await $magento.api.customQueryCacheable({
        query: getQueryDefaultCategories
      });

      return data?.getKlevuDefaultCategoriesWithOrder?.items.sort(
        (a, b) => a.order - b.order
      );
    };

    const populateCategoriesFromKlevuForEmptySuggestions =
      async (): Promise<void> => {
        const defaultCategories = await getDefaultCategories();
        if (defaultCategories) {
          for (const pageRow of defaultCategories) {
            pagesInitial.value.push(PrepareDefaultCategory(pageRow));
          }
        } else {
          const emptyResult = await KlevuFetch(
            searchCategory('', { limit: 3 })
          );
          for (const pagesRow of emptyResult.queriesById('search').records) {
            pagesInitial.value.push(pagesRow);
          }
        }
      };

    const PrepareDefaultCategory = (defaultCategory): Object => {
      const url =
        baseUrl +
        defaultCategory.uri.substring(defaultCategory.uri.indexOf(locale));
      return {
        id: defaultCategory.id,
        name: defaultCategory.name,
        url: url
      };
    };

    const doSearch = async () => {
      notFound.value = true;
      if (quickSearchStore.searchTerm.length < 3) {
        clearSearchResults();
        doEmptySuggestions();
        return;
      }

      let productsQuery: any = {};
      let cmsQuery: any = {};
      let categoryQuery: any = {};
      let suggestionsQuery: any = suggestions(quickSearchStore.searchTerm);

      if (isTypeEnabled(KlevuTypeOfRecord.Product)) {
        productsQuery = search(quickSearchStore.searchTerm, {
          id: 'products',
          limit: 8,
          typeOfRecords: [KlevuTypeOfRecord.Product]
        });
      }

      if (isTypeEnabled(KlevuTypeOfRecord.Cms)) {
        cmsQuery = search(quickSearchStore.searchTerm, {
          id: 'cms',
          limit: 10,
          typeOfRecords: [KlevuTypeOfRecord.Cms]
        });
      }

      if (isTypeEnabled(KlevuTypeOfRecord.Category)) {
        categoryQuery = search(quickSearchStore.searchTerm, {
          id: 'categories',
          limit: quickSearchLimit,
          typeOfRecords: [KlevuTypeOfRecord.Category]
        });
      }

      // if query object is empty, Klevu will skip query
      const result = await KlevuFetch(
        productsQuery,
        cmsQuery,
        categoryQuery,
        suggestionsQuery
      );

      quickSearchStore.setTrendingProducts([]);

      if (isTypeEnabled(KlevuTypeOfRecord.Product)) {
        productsNumber.value = 0;
        skuList.value = [];
        suggestionsList.value = [];

        for (const productRow of result.queriesById('products').records) {
          notFound.value = false;
          skuList.value.push(productRow.sku);
        }

        productsNumber.value =
          result.queriesById('products').meta.totalResultsFound;
        quickSearchStore.setProducts(skuList.value ?? []);
      }

      if (isTypeEnabled(KlevuTypeOfRecord.Cms)) {
        quickSearchStore.setCmsPages(result.queriesById('cms').records ?? []);
      }

      if (isTypeEnabled(KlevuTypeOfRecord.Category)) {
        quickSearchStore.setCategories(
          result.queriesById('categories').records ?? []
        );
      }

      for (const suggestionRow of result.suggestionsById('suggestions')
        .suggestions) {
        notFound.value = false;
        suggestionsList.value.push(suggestionRow.suggest);
      }

      quickSearchStore.setSuggestions(
        result
          .suggestionsById('suggestions')
          .suggestions.map((i) => i.suggest) ?? []
      );

      KlevuLastSearches.save(quickSearchStore.searchTerm);
    };

    const isTypeEnabled = (type) => typeOfRecords.includes(type);

    const doSearchPageSearch = () => {
      const searchPageUrl = window.location.toString();
      const searchPageUrlParameters = window.location.search.toString();
      const finalUrl = searchPageUrl.replace(searchPageUrlParameters, '');
      if (finalUrl) {
        history.replaceState(
          {},
          null,
          finalUrl + '?q=' + quickSearchStore.searchTerm
        );
        root.$emit('do-search-page-search');
      }
    };

    const debouncedSearchHandler = debounce(($event) => {
      quickSearchStore.setSearchTerm($event.target.value);
      if (isSearchPage()) {
        doSearchPageSearch();
      } else {
        doSearch();
      }
    }, 300);

    const toggleQuickSearch: any = (bool) => {
      quickSearchStore.setQuickSearchOpen(isSearchPage() ? false : bool);
    };

    const getInitialSearchResults = () => {
      toggleQuickSearch(true);
      doEmptySuggestions();
      openedWrapper.value = 'opened';
    };

    if (props.searchOnInit) {
      getInitialSearchResults();
    }

    const doSearchSubmit = () => {
      if (quickSearchStore.searchTerm.length) {
        const searchResultsPage = root.localePath({
          name: 'vaimoKlevuSearch-route',
          query: { q: quickSearchStore.searchTerm }
        });
        megaMenuMobileStore.hide();
        megaMenuMobileStore.isMenuLayersVisible = false;
        window.location.replace(searchResultsPage);
      }
    };

    const getClickSearchDetails = () => {
      $gtm.push({
        event: 'clickSearch',
        searchType: 'Products'
      });
    };

    const clearSearch = () => {
      inputQuickSearch.value.value = '';
      quickSearchStore.setSearchTerm('');
      doSearch();
    };

    onUpdated(() => {
      // Accessibility support: recreate focus area
      if (container.value && containerBind.value) {
        setTimeout(() => {
          focusTrap.componentUpdated(container.value);
        }, 1000);
      }
    });

    watch(
      () => openedWrapper.value,
      () => {
        if (!isSearchPage() && document && openedWrapper.value === 'opened') {
          document.body.classList.add('no-scroll');
          // Accessibility support: create focus area
          focusTrap.bind(container.value);
          containerBind.value = true;
        } else {
          document.body.classList.remove('no-scroll');
          focusTrap.unbind(container.value);
          containerBind.value = false;
        }
      }
    );

    return {
      toggleQuickSearch,
      quickSearchStore,
      closeQuickSearch,
      closeQuickSearchOut,
      doEmptySuggestions,
      debouncedSearchHandler,
      getInitialSearchResults,
      doSearchSubmit,
      getSlug,
      strippedHtml,
      inputQuickSearch,
      container,
      skuListTrending,
      suggestionsList,
      pagesInitial,
      skuList,
      productsNumber,
      openedWrapper,
      searchPath,
      isSearchPage,
      searchResultsUrl,
      buildSearchURL,
      getClickSearchDetails,
      closeButtonHandler,
      notFound,
      isPageHasCorrectUrl,
      clearSearch
    };
  },
  watch: {
    $route() {
      this.closeQuickSearch();
    }
  },
  methods: {
    focus() {
      this.getInitialSearchResults();
    }
  }
});
