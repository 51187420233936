






































import {
  defineComponent,
  onBeforeMount,
  computed,
  useMeta,
  onMounted,
  useContext
} from '@nuxtjs/composition-api';
import { useTransformedMeta } from '~/integrations/contentful/src/helper/metaHelper';
import { useContentfulPage } from '~/diptyqueTheme/composable/useContentfulPage';
import { useContentfulGQL } from '~/integrations/contentful/src/composables/useContentfulGQL';
import { useScreenSize } from '~/diptyqueTheme/composable/useScreenSize';
import { useNosto } from '~/diptyqueTheme/composable/useNosto';

export default defineComponent({
  name: 'Page404',
  components: {
    VaimoBanner: () => import('~/components/atoms/VaimoBanner.vue'),
    VaimoButton: () => import('~/components/atoms/VaimoButton.vue'),
    SectionsList: () => import('~/components/templates/SectionsList.vue')
  },
  scrollToTop: true,
  setup() {
    const context = useContext();
    const { transformMeta } = useTransformedMeta();
    const { getPageBySlug, page } = useContentfulPage('page404');
    const { query, queryResponse } = useContentfulGQL('page404');
    const { updateNostoSession, recommendationTypes } = useNosto();
    const sectionsItems = computed(() => page.value?.sectionsCollection?.items);
    const metadata = computed(() => page.value?.metaData);
    const headerBannerData = computed(
      () => queryResponse.value?.assetCollection?.items[0] || {}
    );
    const { isMobile } = useScreenSize();

    useMeta(transformMeta(metadata));

    onBeforeMount(async () => {
      return Promise.all([
        query('getAssetByTitle', {
          title: 'Page 404 Banner'
        }),
        getPageBySlug('page404')
      ]);
    });

    onMounted(async () => {
      updateNostoSession(recommendationTypes.forNotFoundPage, context.route.value?.fullPath || 'page404');
      window.scrollTo(0, 0);
    });

    return {
      sectionsItems,
      headerBannerData,
      isMobile
    };
  },
  head: {}
});
