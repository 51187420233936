





































import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import { SfInput } from '@storefront-ui/vue';
import { extend, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

import { useConfig, useStore } from '~/composables';
import { phoneRegex, phoneRegexJP } from '~/diptyqueTheme/helpers/regexes';

extend('required', {
  ...required,
  message: 'This field is required'
});

extend('phone', {
  validate(value) {
    return {
      valid: phoneRegex.test(value.replace(/\D/g, ''))
    };
  }
});

extend('phone-jp', {
  message:
    'Please enter only half-width numbers without hyphens and up to 11 digits.',
  validate(value) {
    return {
      valid: phoneRegexJP.test(value.replace(/ /g, ''))
    };
  }
});
export default defineComponent({
  name: 'VaimoPhone',
  components: {
    SfInput,
    VaimoPhoneInput: () => import('organisms/VaimoPhoneInput.vue'),
    ValidationProvider
  },
  props: {
    value: {
      required: true,
      type: [String, null]
    },
    autocomplete: {
      required: false,
      type: String,
      default: 'tel'
    },
    classes: {
      required: false,
      type: String,
      default: ''
    }
  },
  emits: ['input', 'isPhoneValid'],
  setup(props, { emit }) {
    const { isJpStore } = useStore();
    const { config } = useConfig();
    const isMalformed = ref(false);
    const PHONE_MIN_LENGTH = 10;
    const PHONE_CODE_LENGTH = 4;
    const errorMessage = ref('This field is required');
    const validationData = ref(null);

    const isPhoneMaskWitFlagEnabled = computed(
      () => config.value.phone_mask_with_flag_enabled
    );

    const localVmodel = computed({
      get() {
        return props.value;
      },
      set(newValue) {
        emit('input', newValue);
        if (isPhoneMaskWitFlagEnabled.value) {
          setHandler(newValue);
        } else {
          emit('isPhoneValid', true);
        }
      }
    });

    const testCharacters = (val) => {
      return /^([()\-+0-9\s]*)?$/.test(val);
    };

    const setHandler = (phoneValue, focus = false) => {
      errorMessage.value = 'This field is required';
      if (!phoneValue) {
        emit('isPhoneValid', false);
      } else if (
        phoneValue.trim().length >= PHONE_MIN_LENGTH &&
        testCharacters(phoneValue)
      ) {
        isMalformed.value = false;
        emit('isPhoneValid', true);
      } else {
        isMalformed.value = true;
        emit('isPhoneValid', false);

        if (phoneValue.trim().length < PHONE_MIN_LENGTH) {
          errorMessage.value = 'The field should have at least 10 characters';
        }

        if (!testCharacters(phoneValue)) {
          errorMessage.value = 'Please enter only numbers.';
        }

        if (
          phoneValue.trim().length > 0 &&
          phoneValue.trim().length < PHONE_CODE_LENGTH &&
          testCharacters(phoneValue) &&
          !focus
        ) {
          isMalformed.value = false;
        }

        if (
          phoneValue.trim().length > 0 &&
          phoneValue.trim().length < PHONE_CODE_LENGTH &&
          testCharacters(phoneValue) &&
          focus
        ) {
          errorMessage.value =
            'Please enter a valid phone number (example: +XXXXXX...)';
          isMalformed.value = true;
        }
      }
    };

    const onValidate = (data) => {
      validationData.value = data?.valid;
    };
    const onFocus = () => {
      setHandler(localVmodel.value, true);
    };

    return {
      localVmodel,
      isPhoneMaskWitFlagEnabled,
      isJpStore,
      errorMessage,
      isMalformed,
      onValidate,
      onFocus
    };
  }
});
