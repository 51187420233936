<template>
  <div class="collection-push-full-width">
    <LoadWhenVisible class="banner">
      <VaimoBanner
        v-bind="visualData"
        :ratio="isDesktop ? '136:41' : visualData.ratio"
        class="collection-push-full-width__banner mb-3md"
      />
      <VaimoHeading
        v-if="headingData.heading"
        heading-style="emphasized"
        heading-level="h3"
        :heading="headingData.heading"
        :heading-link="headingData.headingLink"
        class="collection-push-full-width__heading text-center mb-sm"
      />
      <VaimoTruncatedText
        v-if="headingData.description"
        :text="headingData.description"
        class="collection-push-full-width__description text-center mb-sm"
        truncate="by characters"
        :characters="145"
      />
      <VaimoButtons
        v-if="isButtonsExist"
        :section-data="sectionData"
        class="collection-push-full-width__buttons text-center s:mb-md"
      />
    </LoadWhenVisible>
    <div class="grid-container">
      <div class="col-span-12 col-content">
        <LoadWhenVisible>
          <VaimoProducts
            :display="productsData.layout"
            :wrapper-props="getWrapperProps"
            :sku-list="productsData.listOfSkus"
            loading-type="spinner"
            class="collection-push-full-width__slider"
            unique-key="collection-push-full-width-slider"
          />
        </LoadWhenVisible>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, useContext } from '@nuxtjs/composition-api';

import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import { useFlexibleSection } from '~/diptyqueTheme/composable/useFlexibleSection';

export default defineComponent({
  name: 'DesktopFullWidth',
  components: {
    LoadWhenVisible,
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoTruncatedText: () => import('atoms/VaimoTruncatedText.vue'),
    VaimoButtons: () => import('molecules/VaimoButtons.vue'),
    VaimoProducts: () => import('organisms/VaimoProducts.vue')
  },
  props: {
    sectionData: {
      required: true,
      type: Object
    },
    productsData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const { app } = useContext();
    const { isDesktop } = app.$device;
    const { visualData, headingData } = useFlexibleSection(props.sectionData);

    const isButtonsExist = computed(
      () => props.sectionData?.buttonCollection?.items?.length
    );

    const getWrapperProps = computed(() => {
      const breakpoints = [
        {
          maxWidth: 1920,
          maxItemsVisible: 5
        },
        {
          maxWidth: 1700,
          maxItemsVisible: 5
        },
        {
          maxWidth: 1140,
          maxItemsVisible: 4
        }
      ];

      return {
        slider: {
          desktopShift: 25,
          desktopQuantityVisible: 5,
          variableWidth: true,
          desktopCenterMode: false,
          responsive: breakpoints.map(({ maxWidth, maxItemsVisible }) => ({
            breakpoint: maxWidth,
            settings: {
              slidesToShow: maxItemsVisible,
              slidesToScroll: 2
            }
          }))
        }
      };
    });

    return {
      visualData,
      isButtonsExist,
      getWrapperProps,
      isDesktop,
      headingData
    };
  }
});
</script>

<style lang="scss" scoped>
.collection-push-full-width {
  &__heading {
    ::v-deep {
      .vaimo-heading__title {
        @include for-screen-s {
          padding: 0 var(--spacer-md);
        }
      }
    }
  }

  &__description {
    font-size: var(--font-size--lg);
    max-width: 820px;
    margin: 0 auto;

    @include for-screen-s {
      max-width: 335px;
    }
  }

  &__slider {
    ::v-deep {
      .vaimo-slider__slider {
        padding: 0;
      }
      .vaimo-slider {
        .slick-slider {
          .slick-slide {
            width: 242px;
            min-width: 242px;
            margin: 0 20px 0 0;

            @include for-screen-s {
              width: 150px;
              min-width: 150px;
            }
            .sf-product-card {
              padding: 0;
            }
          }
        }
        @include for-screen-s {
          margin-top: 0;
        }
      }
      .vaimo-grid {
        @include for-screen-s {
          padding: 0;
          gap: var(--spacer-3md) var(--spacer-md);
          .sf-product-card {
            padding: 0;
          }
        }
      }
    }
  }
  .col-content {
    min-width: 0;
    width: 100%;
  }
  .banner {
    width: 100%;
  }
}
</style>
