<!-- New Component Name: Editorial Carousel, EditorialCarousel -->

<template>
  <div class="highlight-ecom">
    <div class="module__header text-center">
      <VaimoHeading
        v-if="heading"
        :heading="heading"
        :heading-link="sectionData.headingLink"
        class="module__heading mb-base"
      />
      <VaimoTruncatedText
        v-if="description"
        :text="description"
        class="module_descr"
        truncate="by lines"
        :lines="truncatedLines"
        animated-text
      />
    </div>

    <VaimoProducts
      v-if="productsSKU.length"
      :unique-key="uid"
      :sku-list="productsSKU"
      :sku-list-type="productGroup.type"
      :nosto-id="productGroup.nostoId"
      :alt-visuals="altVisuals"
      :wrapper-props="sliderProps"
      class="mt-md"
    />
  </div>
</template>

<script>
import { computed, defineComponent } from '@nuxtjs/composition-api';

import { useScreenSize } from '~/diptyqueTheme/composable/useScreenSize';

export default defineComponent({
  name: 'HighlightEcom',
  components: {
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoTruncatedText: () => import('atoms/VaimoTruncatedText.vue'),
    VaimoProducts: () => import('organisms/VaimoProducts.vue')
  },
  props: {
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const { isDesktop } = useScreenSize();
    const heading = computed(() => props.sectionData?.heading);
    const description = computed(() => props.sectionData?.descr);
    const productGroup = computed(() => props.sectionData?.productGroup);
    const productsSKU = computed(() => productGroup.value?.sku || []);
    const truncatedLines = computed(() => (isDesktop.value ? 3 : 2));
    const uid = computed(() => 'he_' + (props.sectionData.sys?.id || ''));
    const sliderProps = { slider: { infinite: true, mobileShift: 25 } };
    const altVisuals = computed(() =>
      props.sectionData?.productGroup?.altvisCollection?.items || []
    );

    return {
      isDesktop,
      heading,
      description,
      truncatedLines,
      productsSKU,
      productGroup,
      sliderProps,
      altVisuals,
      uid
    };
  }
});
</script>

<style lang="scss" scoped>
.module__header {
  margin: 0 var(--spacer-md);
  @include for-screen-m {
    max-width: 610px;
    padding: 0 var(--spacer-md);
    margin: 0 auto;
  }
  @include for-screen-l {
    padding-left: 375px;
    padding-right: 375px;
  }
}
::v-deep .vaimo-truncated-text {
  font-weight: 300;
}
</style>
