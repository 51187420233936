



























import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  ref,
  useContext,
  useFetch,
  useRoute,
  useRouter,
  watch
} from '@nuxtjs/composition-api';
import { SfSteps } from '@storefront-ui/vue';

import { useBooxi } from '~/diptyqueTheme/composable/useBooxi';
import useCart from '~/modules/checkout/composables/useCart';
import cartGetters from '~/modules/checkout/getters/cartGetters';

import { useCheckoutStore } from '../store/checkoutData';

export default defineComponent({
  name: 'CheckoutPage',
  components: {
    SfSteps
  },
  scrollToTop: true,
  setup() {
    const route = useRoute();
    const { app } = useContext();
    const { path } = route.value;
    const router = useRouter();
    const { cart, load } = useCart();
    const products = computed(() => cartGetters.getItems(cart.value));
    const currentStep = computed(() => path.split('/').pop());
    const { hasOneBooxiItemInCart } = useBooxi();
    const checkoutStore = useCheckoutStore();
    const stepsContainer = ref(null);

    const STEPS = ref([
      {
        title: 'Delivery / Shipping Address',
        url: 'shipping-address'
      },
      {
        title: 'Delivery',
        url: 'shipping'
      },
      {
        title: 'Payment',
        url: 'payment'
      }
    ]);

    const getSteps = computed(() => {
      if (hasOneBooxiItemInCart.value) {
        return STEPS.value.filter((step) => step.url !== 'shipping');
      }
      return STEPS.value;
    });

    const currentStepIndex = computed(() =>
      getSteps.value.findIndex((step) => step.url === currentStep.value)
    );
    const isThankYou = computed(() => currentStep.value === 'thank-you');
    const isErrorPage = computed(() => currentStep.value === 'error-page');
    const isUserAccount = computed(() => currentStep.value === 'user-account');
    const handleStepClick = async (stepIndex: number) => {
      if (stepIndex <= currentStepIndex.value) {
        window.scroll(0, 0);
        const { url } = getSteps.value[stepIndex];
        await router.push(`${app.localePath(`/checkout/${url}`)}`);
      }
    };

    useFetch(async () => {
      if (!cart.value?.id && currentStep.value !== 'thank-you') {
        await load();
      }
    });

    onMounted(async () => {
      nextTick(() => {
        window.scroll(0, 0);
      });

      if (
        products.value.length === 0 &&
        currentStep.value !== 'thank-you' &&
        currentStep.value !== 'error-page'
      ) {
        await router.push(app.localePath('/'));
      }
    });

    watch(
      () => stepsContainer.value,
      async (newVal) => {
        if (newVal) {
          await nextTick();
          checkoutStore.updateStepsHeaderHeight(
            stepsContainer.value.$el.firstChild.clientHeight
          );
        }
      }
    );

    return {
      handleStepClick,
      getSteps,
      currentStepIndex,
      isThankYou,
      isErrorPage,
      isUserAccount,
      currentStep,
      stepsContainer
    };
  }
});
