


































import { computed, defineComponent, useRoute } from '@nuxtjs/composition-api';
import { useSocialLogin } from '~/diptyqueTheme/composable/useSocialLogin';
import VaimoButton from '~/diptyqueTheme/components/atoms/VaimoButton.vue';

export default defineComponent({
  name: 'VaimoSocialLogin',
  components: {
    VaimoButton
  },
  setup() {
    const route = useRoute();
    const routePath = computed(() => route.value.path);
    const { loginWithFacebook, loginWithGoogle } = useSocialLogin();
    const isThankYouPage = computed(() =>
      routePath.value.includes('checkout/thank-you')
    );

    return {
      loginWithFacebook,
      loginWithGoogle,
      isThankYouPage
    };
  },
  head() {
    return {
      script: [
        {
          src: 'https://accounts.google.com/gsi/client',
          async: true
        },
        {
          src: 'https://apis.google.com/js/api.js',
          async: true
        },
        {
          src: 'https://connect.facebook.net/en_US/sdk.js',
          async: true
        }
      ]
    };
  }
});
