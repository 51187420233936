










import {
  computed,
  defineComponent,
  useContext,
  useFetch,
  useMeta
} from '@nuxtjs/composition-api';

import { useContentfulPage } from '~/diptyqueTheme/composable/useContentfulPage';
import { useHreflangs } from '~/diptyqueTheme/composable/useHreflangs';

export default defineComponent({
  name: 'Page',
  components: {
    VaimoBreadcrumbs: () => import('atoms/VaimoBreadcrumbs.vue'),
    SectionsList: () => import('templates/SectionsList.vue'),
    PageMagento: () => import('./PageMagento.vue')
  },
  setup() {
    const ctx = useContext();
    const id = ctx.route.value?.params?.slug;
    const { getPage, page, loading, error } = useContentfulPage(id);

    const sectionsItems = computed(() => {
      return page.value?.sectionsCollection?.items || [];
    });

    const pageType = computed(() => {
      return page.value?.type || 'default';
    });

    const metaTitle = computed(() => {
      let title = page.value?.metaData?.title || page.value?.title;

      if (title) {
        return title + ' | Diptyque Paris';
      }

      return null;
    });

    const metaTags = computed(() => {
      return metaTitle.value
        ? {
            title: metaTitle.value,
            meta: [
              {
                hid: 'og:title',
                property: 'og:title',
                content: metaTitle.value
              },
              {
                hid: 'description',
                name: 'description',
                content: page.value?.metaData?.description
              },
              {
                hid: 'og:description',
                name: 'og:description',
                content: page.value?.metaData?.description
              }
            ]
          }
        : {};
    });

    useMeta(() => metaTags.value);

    useFetch(async () => {
      await getPage();

      // FIXME probably following is obsolete, as if not found then PageMagento will do cms lookup
      // FIXME   and if that was also not found, then trigger nuxt error 404.
      // if (page.value?.pageCollection?.items?.length === 0) {
      //   // ctx.error({ statusCode: 404 }); - If no pages in Contentful -> magento search starts. Will be refactored soon.
      // }
    });

    const breadcrumbs = computed(() => {
      if (!page.value?.title) return [];

      return [
        { text: ctx.i18n.t('Home'), link: '/' },
        { text: page.value?.title, link: ctx.route.value?.path }
      ];
    });

    return {
      loading,
      page,
      pageType,
      sectionsItems,
      breadcrumbs
    };
  },
  async asyncData(ctx) {
    const { getHrefLangs } = useHreflangs(ctx);
    const hrefLangsLinks = await getHrefLangs();
    return {
      hrefLangsLinks
    };
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  head() {
    return {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      link: this.hrefLangsLinks
    };
  }
});
