



























































































































































































import {
  computed,
  defineComponent,
  onMounted,
  ref,
  useContext,
  useMeta,
  useRoute,
  useRouter
} from '@nuxtjs/composition-api';
import { SfHeading, SfProperty } from '@storefront-ui/vue';
import VaimoBadge from 'atoms/VaimoBadge.vue';
import VaimoEngravingCard from 'organisms/cart/parts/VaimoEngravingCard.vue';
import VaimoBenefits from 'organisms/VaimoBenefits.vue';
import VaimoContentBlock from 'organisms/VaimoContentBlock.vue';

import { useApi, useStore } from '~/composables';
import { useGoogleTagManager } from '~/diptyqueTheme/composable';
import { formatEventPrice } from '~/diptyqueTheme/composable/useGoogleTagManager/helpers';
import { useNostoOrder } from '~/diptyqueTheme/composable/useNostoOrder';
import guestOrderQuery from '~/diptyqueTheme/customQueries/magento/guestOrder';
import setImaginoFirstPurchaseDateQuery from '~/diptyqueTheme/customQueries/magento/setImaginoFirstPurchaseDate';
import { validateFraction } from '~/diptyqueTheme/helpers/priceFormatter';
import { getItem } from '~/helpers/asyncLocalStorage';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import orderGetters from '~/modules/checkout/getters/orderGetters';
import { useUser } from '~/modules/customer/composables/useUser';
import { useUserOrder } from '~/modules/customer/composables/useUserOrder';
import type { OrderItemInterface, OrderTotal } from '~/modules/GraphQL/types';

interface GuestOrderResponse {
  guestOrder: {
    items?: OrderItemInterface;
    shipping_method?: string;
    total?: OrderTotal;
    customer_firstname?: string;
    customer_lastname?: string;
    customer_email?: string;
    is_gift_message_set?: boolean;
    is_gift_wrapping_set?: boolean;
    applied_coupon?: {
      coupon_code?: string;
      coupon_value?: string;
    };
    splio_src?: string;
  };
}

export default defineComponent({
  name: 'ThankYouStep',
  components: {
    SfProperty,
    SfHeading,
    VaimoBenefits,
    VaimoContentBlock,
    VaimoBadge,
    VaimoEngravingCard,
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoButton: () =>
      import('~/diptyqueTheme/components/atoms/VaimoButton.vue'),
    AccountRegistrationForm: () =>
      import('organisms/account/parts/AccountRegistrationForm.vue'),
    JpSuccessCheckoutTotals: () =>
      import('~/diptyqueTheme/modules/checkout/components/JpSuccessCheckoutTotals.vue')
  },
  scrollToTop: true,
  emits: ['changeStep'],
  setup(props, context) {
    // eslint-disable-next-line no-magic-numbers
    context.emit('changeStep', 4);
    const {
      app: { localeRoute, i18n, $gtm }
    } = useContext();
    const { isJpStore } = useStore();
    const route = useRoute();
    const router = useRouter();
    const { user, isAuthenticated } = useUser();
    const { search } = useUserOrder();
    const orderData = ref([]);

    const orderNumber = computed(() => route.value.query?.order ?? '');
    const customerName = computed(() => user.value?.firstname ?? '');
    const guestName = ref('');
    const totals = ref({
      discount: null,
      subTotal: null,
      subTotalInclTax: null,
      grandTotal: null,
      tax: null,
      shipping: null,
      shippingInclTax: null,
      vwCodFeeInclTax: null
    });
    const shippingMethod = ref(null);
    const products = ref(null);
    const isGiftWrappingSet = ref(null);
    const isGiftMessageSet = ref(null);
    const appliedCouponCode = ref(null);
    const appliedCouponValue = ref(null);
    const splioSrc = ref(null);
    const { query } = useApi();

    // GTM
    const { getPurchaseDetails, EVENT_TIMEOUT } = useGoogleTagManager();

    // Nosto
    const {
      getProductIds: getNostoProductIds,
      getVariables: getNostoVariables
    } = useNostoOrder();
    const nostoUserData = ref(null);
    const nostoOrderData = ref(null);
    const nostoPlaceOrder = async () => {
      const nostoVariables = await getNostoVariables(
        nostoUserData.value,
        nostoOrderData.value
      );
      await getNostoProductIds(nostoVariables);
    };

    const ctaAction = () => {
      if (!isAuthenticated.value) {
        return router.push(localeRoute({ name: 'home' }));
      }
      return router.push(localeRoute({ name: 'customer-order-history' }));
    };

    const getFirstname = () => {
      if (isAuthenticated.value) {
        return customerName.value;
      }
      return guestName.value;
    };

    // Refs - Registration form
    const isRegistrationShowed = ref(false);
    const registrationForm = ref(null);
    const scrollToRegistration = () => {
      if (registrationForm.value) {
        registrationForm.value.scrollIntoView({
          behavior: 'smooth'
        });
      }
      isRegistrationShowed.value = true;
    };

    const getIsPreOrder = (item) => {
      //@ts-ignore
      return item.preorder;
    };

    const getPreOrderLabel = (item) => {
      //@ts-ignore
      return item.preorderStockNotice;
    };

    // Refs - Order summary
    const orderSummary = ref(null);
    const scrollToOrderSummary = () => {
      if (orderSummary.value) {
        orderSummary.value.scrollIntoView({
          behavior: 'smooth'
        });
      }
    };

    const getShippingMethod = (method: string): string => {
      return method ?? '';
    };

    const getTotalsData = (total) => {
      return {
        discount: total?.discounts?.[0]?.amount?.value ?? null,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        subTotal: total?.subtotal?.value ?? null,
        subTotalInclTax: total?.subtotal_incl_tax?.value ?? null,
        grandTotal: total?.base_grand_total?.value ?? null,
        tax: total?.total_tax?.value ?? null,
        shipping: total?.shipping_handling?.amount_excluding_tax?.value ?? null,
        shippingInclTax: total?.shipping_handling?.amount_including_tax?.value ?? null,
        vwCodFeeInclTax: total?.vw_cod_fee?.amount_including_tax?.value ?? null
      };
    };

    const gtmPurchaseEvent = () => {
      try {
        setTimeout(() => {
          $gtm.push(
            getPurchaseDetails(
              products.value,
              totals.value,
              isGiftWrappingSet.value,
              isGiftMessageSet.value,
              appliedCouponCode.value,
              appliedCouponValue.value
                ? formatEventPrice(appliedCouponValue.value)
                : '',
              shippingMethod.value,
              orderNumber.value?.toString()
            )
          );
        }, EVENT_TIMEOUT);
      } catch (e) {
        console.log(
          `error pushing gtm event: purchase: ${e}\n\nOrder Number:${orderNumber.value}`
        );
      }
    };

    onMounted(async () => {
      const checkout = await getItem('checkout');
      if (checkout && checkout['user-account']) {
        const data = checkout['user-account'];
        guestName.value = data?.firstname ?? '';
      }

      if (user.value && isAuthenticated) {
        orderData.value = await search({
          filter: {
            number: { eq: orderNumber.value?.toString() }
          },
          customQuery: {
            customerOrders: 'customerOrdersWithProductImg'
          }
        }).then((res) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const { items, total, shipping_method, is_gift_wrapping_set, is_gift_message_set, applied_coupon, splio_src  } = res?.items?.[0];
          products.value = items ?? null;
          shippingMethod.value = getShippingMethod(shipping_method);
          isGiftWrappingSet.value = is_gift_wrapping_set;
          isGiftMessageSet.value = is_gift_message_set;
          appliedCouponCode.value = applied_coupon?.coupon_code ?? '';
          appliedCouponValue.value = getTotalsData(total)?.discount ?? '';
          splioSrc.value = splio_src;
          totals.value = getTotalsData(total);

          // Set Nosto data for user order
          nostoUserData.value = user.value ?? null;
          nostoOrderData.value = res?.items?.[0] ?? null;

          return res?.items ?? null;
        });
      } else {
        const { data: guestOrderData, errors: guestOrderErrors } =
          await query<GuestOrderResponse>(guestOrderQuery, {
            orderNumber: orderNumber.value?.toString()
          });

        if (guestOrderErrors?.length > 0) {
          throw new Error(String(guestOrderErrors[0].message));
        }

        const {
          guestOrder: {
            items,
            total,
            shipping_method,
            customer_firstname,
            customer_lastname,
            customer_email,
            is_gift_wrapping_set,
            is_gift_message_set,
            applied_coupon,
            splio_src
          }
        } = guestOrderData;
        products.value = items ?? null;
        shippingMethod.value = getShippingMethod(shipping_method);
        totals.value = getTotalsData(total);
        isGiftWrappingSet.value = is_gift_wrapping_set;
        isGiftMessageSet.value = is_gift_message_set;
        appliedCouponCode.value = applied_coupon?.coupon_code ?? '';
        appliedCouponValue.value = getTotalsData(total)?.discount ?? '';
        splioSrc.value = splio_src;

        // Set Nosto data for guest order
        nostoUserData.value = {
          firstname: customer_firstname,
          lastname: customer_lastname,
          email: customer_email
        };
        nostoOrderData.value = guestOrderData?.guestOrder ?? null;
      }
      if (isAuthenticated.value) {
        await query(setImaginoFirstPurchaseDateQuery, {
          order_increment_id: orderNumber.value
        });
      }

      gtmPurchaseEvent();

      await nostoPlaceOrder();
    });

    const metaTags = computed(() => ({
      title: `${i18n.t('Checkout Success Page')} | Diptyque`,
      meta: [
        {
          hid: 'og:title',
          property: 'og:title',
          content: `${i18n.t('Checkout Success Page')} | Diptyque`
        },
        {
          hid: 'robots',
          property: 'robots',
          content: 'noindex, nofollow'
        }
      ]
    }));

    useMeta(metaTags.value);

    const isEngraving = (product) => {
      return product.engraving ? true : false;
    };

    return {
      isAuthenticated,
      orderGetters,
      getFirstname,
      ctaAction,
      orderNumber,
      customerName,
      orderData,
      totals,
      products,
      isRegistrationShowed,
      isJpStore,
      registrationForm,
      scrollToRegistration,
      orderSummary,
      scrollToOrderSummary,
      validateFraction,
      splioSrc,
      getPreOrderLabel,
      getIsPreOrder,
      isEngraving,
      cartGetters
    };
  },
  head: {}
});
