






import {
  computed,
  defineComponent,
  onMounted,
  useContext,
  useFetch,
  useMeta
} from '@nuxtjs/composition-api';

import { useContentfulPage } from '~/diptyqueTheme/composable/useContentfulPage';
import { useHreflangs } from '~/diptyqueTheme/composable/useHreflangs';
import { useNosto } from '~/diptyqueTheme/composable/useNosto';
import { useTransformedMeta } from '~/integrations/contentful/src/helper/metaHelper';

export default defineComponent({
  name: 'Home',
  components: {
    SectionsList: () => import('templates/SectionsList.vue')
  },
  setup() {
    const context = useContext();
    const id = context.route.value?.params?.slug || 'home';

    const { transformMeta } = useTransformedMeta();
    const { getPage, page } = useContentfulPage(id);
    const { updateNostoSession, recommendationTypes } = useNosto();

    const sectionsItems = computed(() => page.value?.sectionsCollection?.items);
    const metadata = computed(() => page.value?.metaData);

    useMeta(transformMeta(metadata));

    useFetch(async () => {
      await getPage();
    });

    onMounted(async () => {
      updateNostoSession(
        recommendationTypes.home,
        context.route.value.fullPath
      );
    });

    return {
      page,
      sectionsItems
    };
  },

  async asyncData(ctx) {
    const { getHrefLangs } = useHreflangs(ctx);
    const hrefLangsLinks = await getHrefLangs();
    return {
      hrefLangsLinks
    };
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  head() {
    return {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      link: this.hrefLangsLinks
    };
  }
});
